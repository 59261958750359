.location-type-select {
    margin: 0 !important;
}

.location-type-select .MuiOutlinedInput-input {
    color: #ffffff;
}

.rgd-main-container {
    max-width: none !important;
}

.rgd-option-container {
    grid-template-columns: repeat(auto-fit, minmax(90px, 1fr)) !important;
}

.MuiFormControl-root {
    background: #272727;
}

.MuiButton-root { 
    color: white !important;
}

.MuiButton-root.Mui-disabled {
    background-color: #272727 !important;
    color: #7a7a7a !important;
}

.location-type-select {
    width: 100% !important;
}

.location-type-select .MuiInputBase-root{
    border-radius: 0;
    padding: 9px 8px !important;
}

.store-search-filter {
    padding: 9px 8px !important;
    border: 1px solid #979797;
}

.MuiInput-underline:after {
    display: none;
}

/* .C-ButtonGroup {
    flex-grow: 1;
}

.C-ButtonGroup__ListItem {
    flex-basis: 0;
}

.C-Button {
    padding: 0;
    border-radius: 0 !important;
} */
