.C-Toaster.\--location-bottom {
    z-index: 9999;
    padding: 0 20px;
    right: 0;
    width: 100%;

    .C-Toaster__Toast.\--location-bottom.isVisible {
        right: 0;
        width: 100%;
        max-width: none;
    }
}