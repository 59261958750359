:root {
    --trueye-background: #121212;
    --trueye-dark-grey: #272727;
    --trueye-text-grey: #BDBDBD;
    --trueye-disabled-grey: #7A7A7A;
  
    --trueye-grey-timeline-future: #414141;
    --trueye-secondary-main-color: #696969;
    --trueye-alternate-main-grey: #565656;
    --trueye-bg-color: #FBFBFB;
    --trueye-light-bg-color: #FBFBFB;
    --trueye-red-color: #c00;
    --trueye-light-grey: #ddd;
    --trueye-black-color: #000;
    --trueye-input-grey: #333;
    --trueye-error-orange: #FFAB40;
    --trueye-light-blue:#90caf9cc;
    --trueye-blue: #90CAF9;
    --trueye-accent-blue: #42A5F5;
    --trueye-white:#FFFFFF;
    --trueye-light-grey-text: #E0E0E0;
  
    --trueye-rgdbtn-background:#333;
    --trueye-rgdbtn-disabled-background:#7a7a7a;
  
    --trueye-grey-border: #808080;

    .MuiPopover-paper {
      background-color: #292929;
    }
  }
